import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'hash',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		// ------ LANGUAGES ------
		{
			path: '/en',
			name: 'EN',
			component: () => import('../views/OverviewView'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('setLanguage', 'en')
				next('/')
			},
		},
		{
			path: '/de',
			name: 'DE',
			component: () => import('../views/OverviewView'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('setLanguage', 'de')
				next('/')
			},
		},
		{
			path: '/br',
			name: 'BR',
			component: () => import('../views/OverviewView'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('setLanguage', 'br')
				next('/')
			},
		},
		// ------ PERSONAL LINK ------
		{
			path: '/invitation/:hash',
			name: 'Invitation',
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('setToken', routeTo.params.hash)
				store.dispatch('loadData', ['timetable/data']).then((response) => {
					if (response) {
						next('/')
					} else {
						next('/404')
					}
				})
			},
		},
		{
			path: '/itinerary/:hash',
			name: 'Itinerary',
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('setToken', routeTo.params.hash)
				store.dispatch('loadData', ['timetable/data']).then((response) => {
					if (response) {
						let route = '/'
						next(route)
					} else {
						next('/404')
					}
				})
			},
		},
		// ------ HOME / LANDING PAGE ------
		{
			path: '/',
			name: 'Home',
			component: () => import('../views/Upload'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (!store.state.language) {
					store.commit('setLanguage', 'en')
				}
				store.dispatch('loadData', ['timetable/my-uploads']).then((response) => {
					if (response) {
						next()
					} else {
						next('/overview')
					}
				})
			},
		},
		// ------ OVERVIEW ------
		{
			path: '/overview',
			name: 'OverviewEN',
			component: () => import('../views/OverviewView'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language !== 'en') {
					store.commit('setLanguage', 'en')
				}
				next()
			},
		},
		{
			path: '/uebersicht',
			name: 'OverviewDE',
			component: () => import('../views/OverviewView'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language !== 'de') {
					store.commit('setLanguage', 'de')
				}
				next()
			},
		},
		{
			path: '/visao-geral',
			name: 'OverviewBR',
			component: () => import('../views/OverviewView'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language !== 'br') {
					store.commit('setLanguage', 'br')
				}
				next()
			},
		},
		// ------ What to Wear ------
		{
			path: '/what-to-wear',
			name: 'DressCodeEN',
			component: () => import('../views/DressCode'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language !== 'en') {
					store.commit('setLanguage', 'en')
				}
				next()
			},
		},
		{
			path: '/dresscode',
			name: 'DressCodeDE',
			component: () => import('../views/DressCode'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language !== 'de') {
					store.commit('setLanguage', 'de')
				}
				next()
			},
		},
		{
			path: '/o-que-vestir',
			name: 'DressCodeBR',
			component: () => import('../views/DressCode'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language !== 'br') {
					store.commit('setLanguage', 'br')
				}
				next()
			},
		},
		// ------ GUIDE ------
		{
			path: '/guide',
			name: 'GuideEN',
			component: () => import('../views/GuideView'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language !== 'en') {
					store.commit('setLanguage', 'en')
				}
				next()
			},
		},
		{
			path: '/reisefuehrer',
			name: 'GuideDE',
			component: () => import('../views/GuideView'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language !== 'de') {
					store.commit('setLanguage', 'de')
				}
				next()
			},
		},
		// ------ GUIDE ------
		{
			path: '/candomble',
			name: 'Candomble',
			component: () => import('../views/CandombleView'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (!store.state.language) {
					store.commit('setLanguage', 'en')
				}
				next()
			},
		},
		{
			path: '/en/candomble',
			name: 'CandombleEN',
			component: () => import('../views/CandombleView'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				store.commit('setLanguage', 'en')
				next()
			},
		},
		{
			path: '/de/candomble',
			name: 'CandombleDE',
			component: () => import('../views/CandombleView'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				store.commit('setLanguage', 'de')
				next()
			},
		},
		{
			path: '/br/candomble',
			name: 'CandombleBR',
			component: () => import('../views/CandombleView'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				store.commit('setLanguage', 'br')
				next()
			},
		},
		// ------ INVITATION ANIMATION ------
		{
			path: '/invitation',
			name: 'InvitationEN',
			component: () => import('../views/InvitationView'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language === '') {
					store.commit('setLanguage', 'en')
				}
				next()
			},
		},
		{
			path: '/einladung',
			name: 'InvitationDE',
			component: () => import('../views/InvitationView'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language !== 'de') {
					store.commit('setLanguage', 'de')
				}
				next()
			},
		},
		{
			path: '/convite',
			name: 'InvitationBR',
			component: () => import('../views/InvitationView'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language !== 'br') {
					store.commit('setLanguage', 'br')
				}
				next()
			},
		},

		// ------ Itinerary ------
		{
			path: '/itinerary',
			name: 'ItineraryEN',
			component: () => import('../views/ScheduleView'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language !== 'en') {
					store.commit('setLanguage', 'en')
				}

				store.dispatch('loadData', ['timetable/schedule']).then((response) => {
					if (response) {
						//console.log('next', routeTo)
						next()
					} else {
						//console.log('404')
						next('/404')
					}
				})
			},
		},
		{
			path: '/ablaufplan',
			name: 'ItineraryDE',
			component: () => import('../views/ScheduleView'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language !== 'de') {
					store.commit('setLanguage', 'de')
				}
				store.dispatch('loadData', ['timetable/schedule']).then((response) => {
					if (response) {
						next()
					} else {
						next('/404')
					}
				})
			},
		},
		{
			path: '/itinerario',
			name: 'ItineraryBR',
			component: () => import('../views/ScheduleView'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language !== 'br') {
					store.commit('setLanguage', 'br')
				}
				store.dispatch('loadData', ['timetable/schedule']).then((response) => {
					if (response) {
						next()
					} else {
						next('/404')
					}
				})
			},
		},

		// ------ MISC ------
		{
			path: '/music',
			name: 'MusicEN',
			component: () => import('../views/Music'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language !== 'en') {
					store.commit('setLanguage', 'en')
				}
				next()
			},
		},
		{
			path: '/musik',
			name: 'MusicDE',
			component: () => import('../views/Music'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language !== 'de') {
					store.commit('setLanguage', 'de')
				}
				next()
			},
		},
		{
			path: '/musica',
			name: 'MusicBR',
			component: () => import('../views/Music'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language !== 'br') {
					store.commit('setLanguage', 'br')
				}
				next()
			},
		},
		{
			path: '/uploads',
			name: 'UploadEN',
			component: () => import('../views/Upload'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language !== 'en') {
					store.commit('setLanguage', 'en')
				}
				store.dispatch('loadData', ['timetable/my-uploads']).then((response) => {
					if (response) {
						next()
					} else {
						next('/404')
					}
				})
			},
		},
		{
			path: '/gallery',
			name: 'GalleryEN',
			component: () => import('../views/Gallery'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('loadCache')
				if (store.state.language !== 'en') {
					store.commit('setLanguage', 'en')
				}
				next()
			},
		},

		// ------ MISC ------
		{
			path: '/404',
			name: '404',
			component: () => import('../views/Error404'),
		},
		{
			path: '*',
			redirect: { name: '404', params: { resource: 'page' } },
			meta: { requiresAuth: false },
		},
	],
})

export default router
