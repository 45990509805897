<template>
	<v-app>
		<v-app-bar
			app
			v-if="showContents"
			absolute
			clipped-left
			color="transparent"
			elevation="0"
			:height="mobile ? '50' : '90'"
			style="z-index: 90">
			<v-app-bar-nav-icon v-show="mobile" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<v-container v-show="!mobile" class="main-navigation">
				<v-tabs height="90" hide-slider background-color="transparent" :centered="tablet" :show-arrows="false">
					<v-tab v-if="user.ID" :to="$t('nav.1.link')">{{ $t('nav.1.label') }}</v-tab>
					<v-tab :to="$t('nav.2.link')">{{ $t('nav.2.label') }}</v-tab>
					<v-tab :to="$t('nav.3.link')" v-show="notBR">{{ $t('nav.3.label') }}</v-tab>
					<v-tab :to="$t('nav.5.link')">{{ $t('nav.5.label') }}</v-tab>
					<v-tab v-if="user.ID" :to="$t('nav.4.link')">{{ $t('nav.4.label') }}</v-tab>
					<v-tab :to="$t('nav.6.link')">{{ $t('nav.6.label') }}</v-tab>
					<v-tab :to="$t('nav.7.link')">{{ $t('nav.7.label') }}</v-tab>
					<v-tab :to="$t('nav.8.link')">{{ $t('nav.8.label') }}</v-tab>
				</v-tabs>
			</v-container>
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" app temporary clipped style="z-index: 91">
			<div class="text-center py-4">
				<img class="branding" src="assets/img/upload-edb4fc0c-bbc5-4bbd-910a-14a50a7e5b17_cX-141_cY-268_cW-1238_cH-937.png" />
			</div>
			<v-list nav dense>
				<v-list-item v-if="user.ID" link :to="$t('nav.1.link')">
					<v-list-item-title>{{ $t('nav.1.label') }}</v-list-item-title>
				</v-list-item>
				<v-list-item link :to="$t('nav.2.link')">
					<v-list-item-title>{{ $t('nav.2.label') }}</v-list-item-title>
				</v-list-item>
				<v-list-item link :to="$t('nav.3.link')" v-show="notBR">
					<v-list-item-title>{{ $t('nav.3.label') }}</v-list-item-title>
				</v-list-item>
				<v-list-item link :to="$t('nav.5.link')">
					<v-list-item-title>{{ $t('nav.5.label') }}</v-list-item-title>
				</v-list-item>
				<v-list-item v-if="user.ID" link :to="$t('nav.4.link')">
					<v-list-item-title>{{ $t('nav.4.label') }}</v-list-item-title>
				</v-list-item>
				<v-list-item link :to="$t('nav.6.link')">
					<v-list-item-title>{{ $t('nav.6.label') }}</v-list-item-title>
				</v-list-item>
				<v-list-item link :to="$t('nav.7.link')">
					<v-list-item-title>{{ $t('nav.7.label') }}</v-list-item-title>
				</v-list-item>
				<v-list-item link :to="$t('nav.8.link')">
					<v-list-item-title>{{ $t('nav.8.label') }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
		<v-main v-if="showContents">
			<router-view />
		</v-main>
		<v-footer v-if="showContents" color="rgba(0,0,0,0.1)" height="48"></v-footer>
	</v-app>
</template>

<script>
import { isMobile, isTablet } from 'mobile-device-detect'
import { mapState } from 'vuex'

export default {
	name: 'App',
	data: () => ({
		useAnimation: true,
		drawer: false,
	}),
	computed: {
		...mapState(['user', 'token', 'language']),
		notBR() {
			return this.language !== 'br'
		},
		showContents() {
			return this.token !== '' || this.language !== ''
		},
		isAnimation() {
			return this.$route.name === 'Home' && this.useAnimation
		},
		mobile() {
			return isMobile && !isTablet
		},
		tablet() {
			return isTablet
		},
	},
	methods: {
		headline() {
			return this.$t('page.headline')
		},
	},
}
</script>
<style>
.branding {
	max-width: 60%;
}
.main-navigation a {
	padding-right: 50px;
	font-family: 'League Gothic', sans-serif;
	font-size: 1.9rem;
	font-weight: normal;
	letter-spacing: 0.5px;
	color: #000 !important;
}
.main-navigation a:hover,
.main-navigation a.v-tab--active {
	color: rgba(0, 0, 0, 0.6) !important;
}
.main-navigation .v-slide-group__prev {
	display: none !important;
}
@media (max-width: 600px) {
	.main-navigation a {
		padding-right: 16px;
		font-size: 1.4rem;
	}
}
</style>
