import axios from 'axios'
import store from '../store'

const apiClient = axios.create({
	baseURL: '/rest-api/',
	withCredentials: false,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
	timeout: 10000,
})

export default {
	get(route) {
		const config = {
			headers: { Authentication: store.state.token },
		}
		return apiClient.get(route, config)
	},
	post(route, data) {
		const config = {
			headers: { Authentication: store.state.token },
		}
		return apiClient.post(route, data, config)
	},
	delete(route) {
		const config = {
			headers: { Authentication: store.state.token },
		}
		return apiClient.delete(route, config)
	},
	download(route, data) {
		const config = {
			headers: { Authentication: store.state.token },
			responseType: 'blob',
		}
		return apiClient.post(route, data, config)
	},
	upload(data) {
		const config = {
			headers: { Authentication: store.state.token },
		}
		return apiClient.post('asset/upload', data, config)
	},
}
