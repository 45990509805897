import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#025b8f',
				secondary: '#7825a6',
				accent: '#2b92e8',
			},
		},
	},
})
