import Vue from 'vue'
import Vuex from 'vuex'
import RestService from '../services/RestService'
import * as notification from './modules/notification.js'
import _ from 'lodash'
import i18n from '@/i18n'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		notification,
	},
	state: {
		version: '2.1.0',
		user: { ID: 0, language: 'en' },
		tokenStorage: 'sas_token',
		languageStorage: 'sas_language',
		token: '',
		language: '',
		persons: [],
		schedule: [],
		counter: 0,
		names: [],
		files: [],
	},
	mutations: {
		setLanguage(state, lang) {
			state.language = lang
			i18n.locale = lang
			localStorage.setItem(state.languageStorage, lang)
		},
		setToken(state, token) {
			localStorage.setItem(state.tokenStorage, token)
			state.token = token
		},
		loadCache(state) {
			const token = localStorage.getItem(state.tokenStorage)
			const lang = localStorage.getItem(state.languageStorage)
			if (token) {
				state.token = token
			}
			if (lang) {
				state.language = lang
				i18n.locale = lang
			} else {
				state.language = 'en'
				i18n.locale = 'en'
			}
		},
	},
	actions: {
		loadData({ dispatch, state, commit }, params) {
			return RestService.get('/' + params.join('/'))
				.then((response) => {
					_.each(response.data, (value, key) => {
						state[key] = value
						if (key === 'user') {
							commit('setLanguage', value.language)
						}
					})
					return true
				})
				.catch((error) => {
					state.setupLoading = false
					dispatch(
						'notification/add',
						{
							type: 'error',
							message: error.response.data.message || error.message,
							duration: 5,
						},
						{ root: true }
					)
					return false
				})
		},
		setStatus({ dispatch, state }, data) {
			return RestService.post('/timetable/invitation', data)
				.then(() => {
					state.user.invitation_status = data.status + ''
					return true
				})
				.catch((error) => {
					state.setupLoading = false
					dispatch(
						'notification/add',
						{
							type: 'error',
							message: error.response.data.message || error.message,
							duration: 5,
						},
						{ root: true }
					)
					return false
				})
		},
		saveFiles({ dispatch, state }, data) {
			return RestService.post('/timetable/my-uploads', { files: data })
				.then((response) => {
					_.forEach(response.data.files, (item) => {
						state.files.push(item)
					})
					return true
				})
				.catch((error) => {
					state.setupLoading = false
					dispatch(
						'notification/add',
						{
							type: 'error',
							message: error.response.data.message || error.message,
							duration: 5,
						},
						{ root: true }
					)
					return false
				})
		},
	},
	getters: {},
})
